/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'core-js/modules/es6.set';
import 'core-js/modules/es6.map';
import 'raf/polyfill';

export const onClientEntry = () => {
  // Without this function body the import will not be picked up.
};
