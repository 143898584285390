// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-basics-colours-tsx": () => import("./../src/pages/basics/colours.tsx" /* webpackChunkName: "component---src-pages-basics-colours-tsx" */),
  "component---src-pages-basics-index-tsx": () => import("./../src/pages/basics/index.tsx" /* webpackChunkName: "component---src-pages-basics-index-tsx" */),
  "component---src-pages-basics-themes-tsx": () => import("./../src/pages/basics/themes.tsx" /* webpackChunkName: "component---src-pages-basics-themes-tsx" */),
  "component---src-pages-basics-typography-tsx": () => import("./../src/pages/basics/typography.tsx" /* webpackChunkName: "component---src-pages-basics-typography-tsx" */),
  "component---src-pages-community-index-tsx": () => import("./../src/pages/community/index.tsx" /* webpackChunkName: "component---src-pages-community-index-tsx" */),
  "component---src-pages-components-alerts-tsx": () => import("./../src/pages/components/Alerts.tsx" /* webpackChunkName: "component---src-pages-components-alerts-tsx" */),
  "component---src-pages-components-button-tsx": () => import("./../src/pages/components/Button.tsx" /* webpackChunkName: "component---src-pages-components-button-tsx" */),
  "component---src-pages-components-checkboxes-tsx": () => import("./../src/pages/components/Checkboxes.tsx" /* webpackChunkName: "component---src-pages-components-checkboxes-tsx" */),
  "component---src-pages-components-date-tsx": () => import("./../src/pages/components/Date.tsx" /* webpackChunkName: "component---src-pages-components-date-tsx" */),
  "component---src-pages-components-fieldset-tsx": () => import("./../src/pages/components/Fieldset.tsx" /* webpackChunkName: "component---src-pages-components-fieldset-tsx" */),
  "component---src-pages-components-file-upload-tsx": () => import("./../src/pages/components/FileUpload.tsx" /* webpackChunkName: "component---src-pages-components-file-upload-tsx" */),
  "component---src-pages-components-flex-grid-tsx": () => import("./../src/pages/components/FlexGrid.tsx" /* webpackChunkName: "component---src-pages-components-flex-grid-tsx" */),
  "component---src-pages-components-footer-tsx": () => import("./../src/pages/components/Footer.tsx" /* webpackChunkName: "component---src-pages-components-footer-tsx" */),
  "component---src-pages-components-header-tsx": () => import("./../src/pages/components/Header.tsx" /* webpackChunkName: "component---src-pages-components-header-tsx" */),
  "component---src-pages-components-index-tsx": () => import("./../src/pages/components/index.tsx" /* webpackChunkName: "component---src-pages-components-index-tsx" */),
  "component---src-pages-components-input-tsx": () => import("./../src/pages/components/Input.tsx" /* webpackChunkName: "component---src-pages-components-input-tsx" */),
  "component---src-pages-components-inset-text-tsx": () => import("./../src/pages/components/InsetText.tsx" /* webpackChunkName: "component---src-pages-components-inset-text-tsx" */),
  "component---src-pages-components-radios-tsx": () => import("./../src/pages/components/Radios.tsx" /* webpackChunkName: "component---src-pages-components-radios-tsx" */),
  "component---src-pages-components-select-tsx": () => import("./../src/pages/components/Select.tsx" /* webpackChunkName: "component---src-pages-components-select-tsx" */),
  "component---src-pages-components-textarea-tsx": () => import("./../src/pages/components/Textarea.tsx" /* webpackChunkName: "component---src-pages-components-textarea-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-patterns-index-tsx": () => import("./../src/pages/patterns/index.tsx" /* webpackChunkName: "component---src-pages-patterns-index-tsx" */),
  "component---src-pages-prototypes-index-tsx": () => import("./../src/pages/prototypes/index.tsx" /* webpackChunkName: "component---src-pages-prototypes-index-tsx" */)
}

